import Vue from 'vue'
import VueRouter from 'vue-router'
// import MainLayout from '@/layouts/Main'
// import store from '@/store'
// import { verifyToken } from '@/helpers/token.js'

// TRY NEW IMPORT FOR IMPROVING PERFORMANCE

const mainLayout = () =>
  import(/* webpackChunkName: "mainLayout" */ '../layouts/Main')

const login = () =>
  import(/* webpackChunkName: "login" */ '../views/Auth/Login/New')
// const login = () =>
//   import(/* webpackChunkName: "login" */ '../views/Auth/Login')

// TEACHER

// TEACHER

// STUDENT

const startCbtStudent = () =>
  import(
    /* webpackChunkName: "startCbtStudent" */ '../views/Student/ExamCBTList/StartExamCBT'
  )

const redirectPage = () =>
  import(/* webpackChunkName: "detailClassesAdmin" */ '../views/RedirectPage')

// const ppdbSwasta = () =>
//   import(/* webpackChunkName: "ppdbStudentSwasta" */ '@/views/PpdbSwasta')
// const successPpdbSwasta = () =>
//   import(
//     /* webpackChunkName: "ppdbStudentSwasta" */ '@/views/PpdbSwasta/Success'
//   )
// const failedPpdbSwasta = () =>
//   import(
//     /* webpackChunkName: "ppdbStudentSwasta" */ '@/views/PpdbSwasta/Failed'
//   )

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   meta: {
  //     authRequired: true,
  //   },
  // },
  {
    path: '/student',
    component: mainLayout,
    redirect: { name: 'Dashboard Student' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['murid'],
    },
    children: [
      {
        path: 'cbt/:id/exam',
        name: 'Cbt Start Exam Student',
        component: startCbtStudent,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: login,
    meta: {
      authRequired: false,
    },
  },

  {
    path: '/redirect',
    name: 'Redirect',
    component: redirectPage,
    meta: {
      authRequired: false,
      generalView: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Auth/404'),
    meta: {
      authRequired: false,
    },
  },

  {
    path: '*',
    redirect: { name: '404' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// router.beforeEach(async (to, from, next) => {
//   console.log(to, from)
//   const token = to.query?.token
//   next({
//     path: '/login',
//     token,
//   })
// })
// router.beforeEach(async (to, from, next) => {
//   const token = to.query?.token
//   const localStorageToken = localStorage.getItem('token')
//   let isAdmin = false

//   if (localStorageToken) {
//     const res = verifyToken(localStorageToken)
//     // console.log(res, 'inihasilverifytoken')
//     if (res.username === 'admin') {
//       isAdmin = true
//     }
//   }
//   const query = token ? { token } : {}
//   // const isOnSEB = store.state.isOnSEB
//   const institusi = store.state.master.institusi
//   if (
//     institusi &&
//     !institusi.is_announcement &&
//     to.name?.split(' ')?.includes('Announcement')
//   ) {
//     next({ name: '404' })
//   }
//   // const isOnSEB = false
//   if (
//     to.name !== 'Cbt Start Exam Student' &&
//     to.matched.some((record) => record.meta.authRequired)
//   ) {
//     // console.log(to)
//     localStorage.setItem('isOnExam', false)
//   }

//   // kondisi 1 : ngecek meta authRequired url yang dituju
//   if (to.matched.some((record) => record.meta.authRequired)) {
//     // ngecek apakah sudah login atau belum
//     if (!localStorage.authorized) {
//       next({
//         path: '/login',
//         query,
//       })
//     } else {
//       if (to.path === '/') {
//         // console.log(to, from, 'masukk1')
//         if (token) {
//           localStorage.clear()
//           localStorage.setItem('version', process.env.VUE_APP_VERSION)
//           store.dispatch('master/FETCH_INSTITUSI', null, { root: true })
//           next({
//             path: '/login',
//             query,
//           })
//         } else {
//           if (localStorage.role.includes('guruMapel')) {
//             // console.log(to, from, 'masukk2')
//             next({
//               name: 'Dashboard Teacher',
//             })
//             // } else if (localStorage.role.includes('murid')) {
//             //   next({
//             //     name: 'Dashboard Student',
//             //   })
//           } else if (localStorage.role.includes('murid')) {
//             if (localStorage.isPpdb) {
//               // next({
//               //   name: 'PPDB Student',
//               // })
//             } else {
//               next({
//                 // name: '',
//               })
//             }
//           } else if (isAdmin) {
//             next({
//               name: 'Master Admin',
//             })
//           } else if (localStorage.role.includes('HR')) {
//             next({
//               name: 'Kehadiran Admin HR',
//             })
//           } else if (localStorage.role.includes('keuangan')) {
//             next({
//               name: 'Keuangan Admin Keuangan',
//             })
//           } else if (localStorage.role.includes('pegawai')) {
//             next({
//               name: 'Dasbor Pegawai',
//             })
//           } else {
//             next()
//           }
//           // else if (localStorage.role.includes('HR')) {
//           //   next({
//           //     name: 'Kehadiran Admin HR',
//           //   })
//           // } else if (localStorage.role.includes('keuangan')) {
//           //   next({
//           //     name: 'Keuangan Admin Keuangan',
//           //   })
//           // } else if (localStorage.role.includes('pegawai')) {
//           //   next({
//           //     name: 'Dasbor Pegawai',
//           //   })
//           // } else {
//           //   next()
//           // }
//         }
//       } else {
//         if (
//           to.matched.some(
//             (record) =>
//               record.meta.role &&
//               !record.meta.role.some((role) =>
//                 JSON.parse(localStorage.role).includes(role),
//               ),
//           )
//         ) {
//           next({
//             name: '404',
//           })
//         } else {
//           if (
//             to.name === 'Cbt Start Exam Student'
//             // &&
//             // !isOnSEB &&
//             // store.getters.cbtType !== 'PH' &&
//             // store.getters.cbtType !== 'PPDB'
//           ) {
//             next({
//               name: '404',
//             })
//             // next()
//           } else {
//             if (to.matched.some((record) => record.path.startsWith('/admin'))) {
//               if (isAdmin) {
//                 next() // Jika isAdmin === true, izinkan akses
//               } else {
//                 next({
//                   name: '404', // Jika bukan admin, arahkan ke halaman 404
//                 })
//               }
//             } else {
//               next() // Jika bukan rute /admin, lanjutkan navigasi
//             }
//           }
//         }
//       }
//     }
//   } else {
//     if (to.meta.generalView) {
//       next()
//     } else {
//       if (
//         to.name !== '404' &&
//         !to.meta.authRequired &&
//         localStorage.authorized
//       ) {
//         if (to.meta.ppdbPage) {
//           // console.log('masuk ppdb')
//           next()
//         } else {
//           if (localStorage.role.includes('guruMapel')) {
//             // console.log(to, from, 'masukk5')
//             next({
//               name: 'Dashboard Teacher',
//             })
//           } else if (localStorage.role.includes('murid')) {
//             next({
//               // name: 'Dashboard Student',
//             })
//           } else if (isAdmin) {
//             // console.log(to)
//             next({
//               name: 'Master Admin',
//             })
//           } else if (localStorage.role.includes('HR')) {
//             next({
//               name: 'Kehadiran Admin HR',
//             })
//           } else if (localStorage.role.includes('keuangan')) {
//             next({
//               name: 'Keuangan Admin Keuangan',
//             })
//           } else if (localStorage.role.includes('pegawai')) {
//             next({
//               name: 'Dasbor Pegawai',
//             })
//           } else {
//             next()
//           }
//           // else if (localStorage.role.includes('HR')) {
//           //   next({
//           //     name: 'Kehadiran Admin HR',
//           //   })
//           // } else if (localStorage.role.includes('keuangan')) {
//           //   next({
//           //     name: 'Keuangan Admin Keuangan',
//           //   })
//           // } else if (localStorage.role.includes('pegawai')) {
//           //   next({
//           //     name: 'Dasbor Pegawai',
//           //   })
//           // } else {
//           //   next()
//           // }
//         }
//       } else if (to.path === '/') {
//         // console.log(to, from, 'masukk6')
//         next({
//           path: '/login',
//           query,
//         })
//       } else {
//         // console.log(to, from, 'masukk7')
//         next()
//       }
//     }
//   }
// })

export default router
